<!-- //
文章管理
@auth xuyd
@date 2022-09-10
//-->
<template>
  <List :content-type="3"/>
</template>
<script>
  import List from '../common/article-list'

  export default {
    name: 'ProductionList',
    components: {List},
    data() {
      return {};
    },
    methods: {}
  }
</script>

<style lang="less" scoped>
</style>
